/** @jsx jsx */
import { jsx } from "theme-ui";
import { FunctionComponent } from "react";

export const HandWithHeart: FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 512 512">
      <path
        className="lobsterLightest"
        d="M365.149,247.9625l.3685.7369-31.3191,16.8754a32.926,32.926,0,0,0-21.7391-8.18h-9.8011l26.087-14.1488a30.1059,30.1059,0,0,1,36.4038,4.7162Z"
      />
      <path
        className="lobsterLightest transparent"
        d="M401.7739,254.4473c-5.4532-9.58-18.4229-15.0331-28.224-10.0221l-39.3515,21.2233a32.8909,32.8909,0,0,1,10.759,19.4547l8.1062-4.2741Z"
      />
      <path
        className="white"
        d="M407.6693,251.3523l-62.7119,33.7509a32.8854,32.8854,0,0,0-32.4982-27.6345H268.2441A51.9032,51.9032,0,0,1,245.4,252.2366l-4.5689-2.2108a73.525,73.525,0,0,0-76.2712,7.4429l-10.98,9.801c-5.969,5.2322-71.5549,38.762-71.5549,38.762l45.1732,69.1231,66.3965-37.9514a24.4917,24.4917,0,0,1,18.0545-2.3581l66.3965,12.2329a80.3518,80.3518,0,0,0,50.0369-7.0745L443.0415,261.08a26.4469,26.4469,0,0,0-35.3722-9.7274Z"
      />
      <path
        className="midnight"
        d="M326.8585,239.7727,301.68,253.4738H268.2442a49.4939,49.4939,0,0,1-21.2178-4.8388l-4.5712-2.212a77.2555,77.2555,0,0,0-80.3213,7.834,1.2057,1.2057,0,0,1-.17.127l-.0927.0683-10.98,9.8008c-3.9717,3.5293-44.457,24.8487-70.7012,38.3184a4.29,4.29,0,0,0-2.0351,2.5039,3.7619,3.7619,0,0,0,.5185,3.0967l45.1856,69.1416a3.8124,3.8124,0,0,0,3.2656,1.7627,4.4388,4.4388,0,0,0,1.9873-.5371l66.3721-37.9385a20.75,20.75,0,0,1,15.1435-2.0488.7608.7608,0,0,1,.2041.0595l66.5674,12.249a85.1391,85.1391,0,0,0,52.5264-7.3994,1.1166,1.1166,0,0,0,.2851-.1533,1.9849,1.9849,0,0,1,.2266-.14l114.9-78.89a3.9493,3.9493,0,0,0,1.1133-5.29l-.0059-.0088a30.5148,30.5148,0,0,0-40.6758-11.0928l-2.7256,1.4483-.26-.3233c-7.7051-9.6133-20.9365-13.1221-30.87-8.2207v.0274l-5.5049,2.957-.26-.2256A34.0259,34.0259,0,0,0,326.8585,239.7727Zm82.7842,15.0449a22.5709,22.5709,0,0,1,27.7294,4.8232l.378.4238-111.586,76.4825a77.0725,77.0725,0,0,1-47.3232,6.6015l-66.3252-12.2324a28.7372,28.7372,0,0,0-20.89,2.8184l-63.1894,36.1191L87.713,307.5353l.4941-.2539c14.5469-7.4628,62.4414-32.1962,67.9873-37.1425l10.832-9.6534a69.32,69.32,0,0,1,72.1075-6.9306l4.5673,2.2109a57.0056,57.0056,0,0,0,24.543,5.624h44.2891a28.9341,28.9341,0,0,1,28.5693,24.2188l.1035.6113-33.3379-1.5019a188.9829,188.9829,0,0,0-34.6914,1.6172,3.9855,3.9855,0,0,0-2.6,1.538,3.8274,3.8274,0,0,0-.7334,2.9024,3.988,3.988,0,0,0,1.5391,2.6055,3.835,3.835,0,0,0,2.9023.7343,179.0694,179.0694,0,0,1,33.26-1.5527l37.7295,1.6953.1992-.001a3.8,3.8,0,0,0,2.667-1.1064,3.8772,3.8772,0,0,0,1.2539-2.8154c0-.86,0-1.7491-.0723-2.6885l-.0244-.3242Zm-34.3292-6.9326c6.18-3.126,14.7022-1.1329,20.2647,4.7451l.4492.4746-48.4853,26.09-.1866-.5683a36.3588,36.3588,0,0,0-6.3964-11.6309l-.3819-.47Zm-17.1142-.5625.7051.4589L334.671,260.8605l-.2559-.1914a37.1053,37.1053,0,0,0-15.4873-6.6875l-1.4014-.2548,13.18-7.1553A26.1615,26.1615,0,0,1,358.1993,247.3225Z"
      />
      <path
        className="skyBlue thinStroke lineCap lineJoin"
        d="M110.0363,274.8851l58.9107,92.6681-44.6807,28.4043L65.3556,303.2893Z"
      />
      <path
        className="midnight"
        d="M129.6955,368.677a7.3328,7.3328,0,1,1,10.0685,2.171A7.2836,7.2836,0,0,1,129.6955,368.677Z"
      />
      <path
        className="midnight"
        d="M311.1106,140.1208c-.0762-.0009-.1514-.0009-.2276-.0009a32.2487,32.2487,0,0,0-23.6054,10.3086,31.836,31.836,0,0,0-23.47-10.31c-.123,0-.247.001-.3691.0019a32.6265,32.6265,0,0,0-32.5889,32.59c0,30.94,53.7715,60.3945,54.2676,60.6494a3.8855,3.8855,0,0,0,2.1738.6621,3.6044,3.6044,0,0,0,2.0176-.5918c.544-.289,54.3858-29.2715,54.3858-60.72A32.6286,32.6286,0,0,0,311.1106,140.1208Zm-27.3155,19.3165a4.3054,4.3054,0,0,0,6.001.9541,4.3729,4.3729,0,0,0,.9922-1.0088A24.2746,24.2746,0,0,1,335.38,172.71c.001,23.5986-42.0889,48.8672-48.1,52.3545-8.624-5.1221-48.1172-29.8184-48.1172-52.3535a24.3113,24.3113,0,0,1,24.2949-24.2764l.3184-.0019A23.6764,23.6764,0,0,1,283.7951,159.4373Z"
      />
      <path
        className="lobster"
        d="M306.03,148.4177A24.7745,24.7745,0,0,1,335.88,172.71c.0008,23.9761-41.8959,49.071-48.6025,52.9332-9.0978-5.3765-48.6144-30.058-48.6144-52.9324A24.8065,24.8065,0,0,1,263.45,147.9347c.1095-.0017.2182-.0025.3268-.0025a24.0974,24.0974,0,0,1,20.4221,11.2113,3.7928,3.7928,0,0,0,6.17-.0348A24.6141,24.6141,0,0,1,306.03,148.4177Z"
      />
    </svg>
  );
};
