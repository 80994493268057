/** @jsx jsx */
import { FunctionComponent } from "react";
import { jsx } from "theme-ui";

export const HighFive: FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 512 512" fill="none">
      <path
        className="white"
        d="M417.74,270.6V204.73c0-6.72-6-14.95-13.49-14.95s-13.5,8.23-13.5,14.95V173.94c0-6.71-6-14.95-13.5-14.95s-13.37,8.24-13.49,14.82v-9.62c0-6.72-6-14.95-13.5-14.95s-13.62,7.09-13.49,14.95v11h-.13c-.5-6.34-6.25-13.81-13.25-13.81-7.49,0-13.49,8.23-13.49,14.95v98.43s1.25,7.6-1.38-1.27-8.12-16.34-13.87-23.43c-2.87-3.55-5.87-7.1-9.62-9.76A17.61,17.61,0,0,0,272.16,237c-4.5.89-10.25,5.7-9.12,12.79a64.76,64.76,0,0,1,5.87,8.11,74.41,74.41,0,0,1,9,21.28c2,8.11,3.75,16.85,3.87,25.21a96.55,96.55,0,0,0,4.75,29.27c2.75,8.61,20.62,29.89,37.49,38.51l.12.12H324l3.87,14.45h69.86v-1.65l.12,1.65v-10.9c10.75-9,20.25-22.8,22.5-35C424.12,318.87,420.74,291.63,417.74,270.6Z"
      />
      <path
        className="noFill thinStroke join lineCap"
        d="M327.64,386.77V374c-17.74-7-38.36-1-41.36-40.28a104.57,104.57,0,0,1-4.75-29.27c-.12-8.36-1.87-17.1-3.87-25.21a71.39,71.39,0,0,0-14.75-29.39c-1.12-7.09,4.75-11.9,9.25-12.79s9.12.76,12.87,3.29,6.75,6.08,9.62,9.76c5.75,7.09,11.25,14.69,13.87,23.43S311,290.05,311,300.39"
      />
      <path
        className="noFill thinStroke join lineCap"
        d="M336.77,250.84l.12-86.53c-.12-7.85,6-14.94,13.5-14.94s13.49,8.23,13.49,14.94v86.53"
      />
      <path
        className="noFill thinStroke join lineCap"
        d="M363.76,173.94c0-6.71,6-14.95,13.49-14.95s13.5,8.24,13.5,14.95v84.37"
      />
      <path
        className="thinStroke lineCap lineJoin skyBlue"
        d="M410,386.77H315.15V432.5H410Z"
      />
      <path
        className="midnight"
        d="M332.52,415.9a6.34,6.34,0,1,0-6.25-6.33A6.29,6.29,0,0,0,332.52,415.9Z"
      />
      <path
        className="noFill thinStroke join lineCap"
        d="M390.75,204.73c0-6.72,6-14.95,13.5-14.95s13.49,8.23,13.49,14.95v66c3,21,6.38,48.26,2.25,70.18-2.25,12.16-11.75,26-22.49,35v10.9"
      />
      <path
        className="noFill thinStroke join lineCap"
        d="M336.89,176.35c0-6.59-6.12-14.95-13.5-14.95s-13.49,8.23-13.49,14.95v102"
      />
      <path
        className="white"
        d="M97.75,270.6V204.73c0-6.72,6-14.95,13.49-14.95s13.5,8.23,13.5,14.95V173.94c0-6.71,6-14.95,13.5-14.95s13.37,8.24,13.49,14.82v-9.62c0-6.72,6-14.95,13.5-14.95s13.62,7.09,13.49,14.95v11h.13c.5-6.34,6.25-13.81,13.25-13.81,7.49,0,13.49,8.23,13.49,14.95v98.43s-1.25,7.6,1.38-1.27,8.12-16.34,13.87-23.43c2.87-3.55,5.87-7.1,9.62-9.76A17.61,17.61,0,0,1,243.33,237c4.5.89,10.25,5.7,9.12,12.79a64.76,64.76,0,0,0-5.87,8.11,74.41,74.41,0,0,0-9,21.28c-2,8.11-3.75,16.85-3.87,25.21A96.55,96.55,0,0,1,229,333.69c-2.75,8.61-20.62,29.89-37.49,38.51l-.12.12h.12l-3.87,14.45H117.74v-1.65l-.12,1.65v-10.9c-10.75-9-20.25-22.8-22.5-35C91.37,318.87,94.75,291.63,97.75,270.6Z"
      />
      <path
        className="noFill thinStroke join lineCap"
        d="M184.36,386.77V374c17.74-7,38.36-31,41.36-40.28a104.57,104.57,0,0,0,4.75-29.27c.13-8.36,1.88-17.1,3.88-25.21a73.53,73.53,0,0,1,8.87-21.28,74.94,74.94,0,0,1,5.87-8.11c1.13-7.09-4.75-11.9-9.25-12.79s-9.12.76-12.87,3.29-6.75,6.08-9.62,9.76c-5.75,7.09-11.25,14.69-13.87,23.43S201,290.05,201,300.39"
      />
      <path
        className="noFill thinStroke join lineCap"
        d="M175.24,250.84l-.13-86.53c.13-7.85-6-14.94-13.49-14.94s-13.5,8.23-13.5,14.94v86.53"
      />
      <path
        className="noFill thinStroke join lineCap"
        d="M148.24,173.94c0-6.71-6-14.95-13.49-14.95s-13.5,8.24-13.5,14.95v84.37"
      />
      <path
        className="thinStroke lineCap lineJoin skyBlue"
        d="M102,386.77h94.85V432.5H102Z"
      />
      <path
        className="midnight"
        d="M179.49,415.9a6.34,6.34,0,1,1,6.24-6.33A6.28,6.28,0,0,1,179.49,415.9Z"
      />
      <path
        className="noFill thinStroke join lineCap"
        d="M121.25,204.73c0-6.72-6-14.95-13.49-14.95s-13.5,8.23-13.5,14.95v66c-3,21-6.37,48.26-2.25,70.18,2.25,12.16,11.75,26,22.49,35v10.9"
      />
      <path
        className="noFill thinStroke join lineCap"
        d="M175.11,176.35c0-6.59,6.13-14.95,13.5-14.95s13.49,8.23,13.49,14.95v102"
      />
      <path
        className="lobsterLight thinStroke"
        d="M237.42,79.5l18,42.32,18-42.32Z"
      />
      <path
        className="lobsterLight thinStroke"
        d="M183.38,118l43,15.8L208.07,91.59Z"
      />
      <path
        className="lobsterLight thinStroke"
        d="M328.74,118l-42.95,15.8,18.26-42.22Z"
      />
      3
    </svg>
  );
};
