/** @jsx jsx */
import { jsx } from "theme-ui";
import { FunctionComponent } from "react";

export const Curtains: FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 512 512">
      <path
        className="lobsterLight"
        d="M380.06,115.32a4.8,4.8,0,0,0-4.83-4.81H137.34a4.81,4.81,0,0,0-4.83,4.81V393.86a4.67,4.67,0,0,0,1.49,3.48,5.21,5.21,0,0,0,3.57,1.34l5.94-.3a4.84,4.84,0,0,0,3.2-1.41l8.18-8.15a8.85,8.85,0,0,1,12.49,0l4.46,4.45a19.1,19.1,0,0,0,26.24,0l4.39-4.37a8.83,8.83,0,0,1,12.48,0l4.46,4.44a19.09,19.09,0,0,0,26.25,0L250,389a8.85,8.85,0,0,1,12.49,0l4.46,4.45a19.1,19.1,0,0,0,26.24,0l4.39-4.37a8.47,8.47,0,0,1,4.68-2.3l.3-.07h2.53v-.15l.3.07a8.51,8.51,0,0,1,4.68,2.3l4.46,4.44a19.08,19.08,0,0,0,26.24,0L345.2,389a8.85,8.85,0,0,1,12.49,0l8.17,8.15a5,5,0,0,0,3.2,1.41l5.95.29h.22a4.8,4.8,0,0,0,4.83-4.81Z"
      />
      <path
        className="lobsterMedium"
        d="M205.64,110.51h-68.3a4.81,4.81,0,0,0-4.83,4.81V393.86a4.67,4.67,0,0,0,1.49,3.48,5.21,5.21,0,0,0,3.57,1.34l5.94-.3a4.84,4.84,0,0,0,3.2-1.41l8.18-8.15a8.85,8.85,0,0,1,12.49,0l4.46,4.45a19.1,19.1,0,0,0,26.24,0l4.39-4.37a8.79,8.79,0,0,1,3.17-2Z"
      />
      <path
        className="midnight"
        d="M438.39,142.37a16.23,16.23,0,0,0,.66-12.52A16.71,16.71,0,0,0,423.47,119a16.33,16.33,0,0,0-15.41,10.92l-.58,1.44H384.09v-20.7a4,4,0,0,0-4-4H131.93a4,4,0,0,0-4,4v20.7H104.5l-.56-1.4a16.43,16.43,0,0,0-21-10,16.15,16.15,0,0,0-9.31,8.33,16.39,16.39,0,0,0,20.28,22.54,16.53,16.53,0,0,0,10-10l.57-1.43h23.39V401.19a3.86,3.86,0,0,0,1.22,2.89,4.4,4.4,0,0,0,3,1.13l6.15-.31a4,4,0,0,0,2.68-1.18l8.53-8.5a10.23,10.23,0,0,1,7.31-3,10,10,0,0,1,7.14,3l4.64,4.63a18.91,18.91,0,0,0,26,0l4.55-4.54a10.2,10.2,0,0,1,7.26-3h.06a9.94,9.94,0,0,1,7.13,3l4.65,4.63a18.91,18.91,0,0,0,26,0l4.56-4.54a10.27,10.27,0,0,1,7.31-3,10,10,0,0,1,7.13,3l4.65,4.62a18.92,18.92,0,0,0,26,0l4.55-4.54a9.75,9.75,0,0,1,5.41-2.67l.5-.1h1.63v-.43l1.55.39a9.76,9.76,0,0,1,5.35,2.65l4.66,4.64a18.9,18.9,0,0,0,26,0l4.56-4.54a10.19,10.19,0,0,1,7.25-3h.06a10,10,0,0,1,7.13,3l8.53,8.49a4.27,4.27,0,0,0,2.69,1.18l6.19.31h.18a4,4,0,0,0,4-4V139.41h23.39l.56,1.39a16.48,16.48,0,0,0,30.35,1.57ZM88.5,143.72a8.34,8.34,0,1,1,8.37-8.34A8.36,8.36,0,0,1,88.5,143.72ZM200.65,385.15a18.1,18.1,0,0,0-7.47,4.4l-4.56,4.54a10.37,10.37,0,0,1-14.51,0l-4.66-4.65a18.35,18.35,0,0,0-25.88,0l-7.84,7.81V114.68h66.44V382.57a1.47,1.47,0,0,1,.08.46l.14,1.69Zm175.13,12.19L368,389.53a18.32,18.32,0,0,0-25.88,0l-4.66,4.64a10.38,10.38,0,0,1-14.51,0l-4.66-4.65a17.28,17.28,0,0,0-7.4-4.38l-.84-.23a14.34,14.34,0,0,0-9.85.25l-.13.05a19.06,19.06,0,0,0-7.44,4.4L288,394.17a10.21,10.21,0,0,1-14.44,0l-4.65-4.64a18.32,18.32,0,0,0-25.88,0l-4.65,4.64a10.38,10.38,0,0,1-14.51,0l-4.66-4.65a17.7,17.7,0,0,0-7.48-4.38l-1.58-.43v-1.61a2.74,2.74,0,0,1,.08-.61V114.68H375.78Zm47.69-253.62a8.34,8.34,0,1,1,8.37-8.34A8.36,8.36,0,0,1,423.47,143.72Z"
      />
      <path
        className="midnight"
        d="M355.24,131H274.6a4.41,4.41,0,1,0,0,8.81h80.64a4.41,4.41,0,1,0,0-8.81Z"
      />
    </svg>
  );
};
