import { SxStyleProp } from "theme-ui";

export const iconStyles: SxStyleProp = {
    ".white": {
        fill: "#fcf2f5",
    },
    ".cipriaMedium": {
        fill: "#ffdcdc",
    },
    ".lobsterMedium": {
        fill: "#da7c49",
    },
    ".lobsterLight": {
        fill: "#e39d76",
    },
    ".lobsterLightest": {
        fill: "#edbda4",
    },
    ".lobster": {
        fill: "#d15b1b",
    },

    ".skyBlueLight": {
        fill: "#ae94fd",
    },

    ".skyBlueMedium": {
        fill: "#9370fc",
    },
    ".skyBlue": {
        fill: "#784cfb",
    },

    ".midnightLight": {
        fill: "#848595",
    },
    ".midnight": {
        fill: "text",
    },
    ".midnightMedium": {
        fill: "#5b5c71",
    },

    ".skyBlueLightest": {
        fill: "#c9b7fd",
    },
    ".stroke": {
        stroke: "text",
        strokeWidth: "8px",
    },
    ".thinStroke": {
        stroke: "text",
        strokeWidth: "7px",
    },
    ".thinnestStroke": {
        stroke: "text",
        strokeWidth: "5px",
    },
    ".transparent": {
        opacity: 0.75,
    },
    ".lineCap": {
        strokeLinecap: "round",
    },
    ".rounded": {
        stroke: "text",
        strokeWidth: "8px",
    },
    ".lineJoin": {
        strokeLinejoin: "round",
    },
    ".noFill": {
        fill: "none",
    },
    ".join": {
        strokeMiterlimit: 10,
    },
};
