/** @jsx jsx */
import { jsx } from "theme-ui";
import { FunctionComponent } from "react";

export const Swarm: FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 512 512">
      <path
        className="skyBlueLight stroke join"
        d="M332.1384,151.34a29.4361,29.4361,0,0,0-19.3941,7.2921l-53.4514,46.812h72.8455a27.0521,27.0521,0,1,0,0-54.1041Z"
      />
      <path
        className="skyBlueLightest stroke join"
        d="M310.7721,241.8491a17.9845,17.9845,0,0,1-12.9748-5.5309L268.164,205.4434h42.17a18.4216,18.4216,0,0,1,18.4217,18.4218A17.9841,17.9841,0,0,1,310.7721,241.8491Z"
      />
      <path
        className="skyBlueLightest stroke join"
        d="M163.3824,241.8491a17.9843,17.9843,0,0,0,12.9747-5.5309l29.6334-30.8748H163.82a18.4217,18.4217,0,0,0-18.4218,18.4218A17.9842,17.9842,0,0,0,163.3824,241.8491Z"
      />
      <path
        className="skyBlueLight stroke join"
        d="M142.0155,151.34a29.4364,29.4364,0,0,1,19.3942,7.2921l53.4513,46.812H142.0155a27.0521,27.0521,0,0,1,0-54.1041Z"
      />
      <path
        className="lobsterMedium thinStroke join"
        d="M251.3158,163.5839H222.8386a20.9295,20.9295,0,0,0,0,41.859h28.4772a20.9295,20.9295,0,0,0,0-41.859Z"
      />
      <path
        className="white thinStroke join"
        d="M237.0772,141.7425a21.8414,21.8414,0,0,0-21.8414,21.8414h43.6828A21.8414,21.8414,0,0,0,237.0772,141.7425Z"
      />
      <path
        className="lobsterLightest thinStroke join"
        d="M274.5863,212.6734a14.9319,14.9319,0,0,0-12.8492-7.23h-49.32a14.9308,14.9308,0,0,0-12.8492,7.23,43.65,43.65,0,0,0-6.0277,25.061h87.0742A43.6515,43.6515,0,0,0,274.5863,212.6734Z"
      />
      <path
        className="lobsterLightest thinStroke join"
        d="M237.0772,302.317l27.4651-32.291h-54.93Z"
      />
      <path
        className="lobsterMedium thinStroke join"
        d="M280.6146,237.7345H193.54a43.4594,43.4594,0,0,0,10.3,25.5048l5.7721,6.7867h54.93l5.7721-6.7867A43.4633,43.4633,0,0,0,280.6146,237.7345Z"
      />
      <path
        className="midnight"
        d="M232.9633,139.7a31.4685,31.4685,0,0,0-30.4155-24.0215,3.9639,3.9639,0,1,0,0,7.9278A23.3273,23.3273,0,0,1,225.6186,143.41C227.7729,142.4557,231.49,140.569,232.9633,139.7Z"
      />
      <path
        className="midnight"
        d="M241.9421,140.8751a31.4684,31.4684,0,0,1,28.6643-26.0862,3.9639,3.9639,0,1,1,.5535,7.9084,23.3272,23.3272,0,0,0-21.632,21.366C247.3123,143.2619,243.4728,141.6393,241.9421,140.8751Z"
      />
      <path
        className="skyBlueLight thinStroke join"
        d="M382.0224,311.2009a16.1783,16.1783,0,0,0-10.6592,4.0078L341.9859,340.937h40.0365a14.8681,14.8681,0,0,0,0-29.7361Z"
      />
      <path
        className="skyBlueLightest thinStroke join"
        d="M370.2793,360.9455a9.8845,9.8845,0,0,1-7.1311-3.04l-16.2867-16.969h23.1772a10.1247,10.1247,0,0,1,10.1247,10.1247A9.8842,9.8842,0,0,1,370.2793,360.9455Z"
      />
      <path
        className="skyBlueLightest thinStroke join"
        d="M289.2727,360.9455a9.8841,9.8841,0,0,0,7.131-3.04l16.2867-16.969H289.5133a10.1246,10.1246,0,0,0-10.1247,10.1247A9.8841,9.8841,0,0,0,289.2727,360.9455Z"
      />
      <path
        className="skyBlueLight thinStroke join"
        d="M277.5293,311.2009a16.1781,16.1781,0,0,1,10.6592,4.0078l29.3773,25.7283H277.5293a14.8681,14.8681,0,0,1,0-29.7361Z"
      />
      <path
        className="lobsterMedium thinStroke join"
        d="M337.6016,317.93H321.95a11.5031,11.5031,0,0,0,0,23.0061h15.6513a11.5031,11.5031,0,1,0,0-23.0061Z"
      />
      <path
        className="white thinStroke join"
        d="M329.776,305.9261A12.0042,12.0042,0,0,0,317.7718,317.93H341.78A12.0042,12.0042,0,0,0,329.776,305.9261Z"
      />
      <path
        className="lobsterLightest thinStroke join"
        d="M350.3913,344.91a8.2067,8.2067,0,0,0-7.062-3.9739H316.2224a8.206,8.206,0,0,0-7.062,3.9739,24.0053,24.0053,0,0,0,2.3482,27.7915l3.1724,3.73,15.095,17.7473,15.095-17.7473,3.1724-3.73a23.8882,23.8882,0,0,0,5.6611-14.0176h0A23.9912,23.9912,0,0,0,350.3913,344.91Z"
      />
      <path
        className="midnight"
        d="M328.7305,304.5138a18.6084,18.6084,0,0,0-17.9326-14.1631,3.4287,3.4287,0,1,0,0,6.8574,11.5064,11.5064,0,0,1,11.4453,9.8233l.2461,1.6172,1.4951-.6631c1.19-.5274,3.2929-1.59,4.165-2.1055l.7959-.4687Z"
      />
      <path
        className="midnight"
        d="M348.7539,290.3507a18.6089,18.6089,0,0,0-17.9326,14.1631l-.2149.8975.7959.4687c.876.5176,2.9786,1.58,4.1661,2.1055l1.4951.6631.2461-1.6172a11.5038,11.5038,0,0,1,11.4443-9.8233,3.4287,3.4287,0,1,0,0-6.8574Z"
      />
      <path
        className="skyBlueLight thinnestStroke join"
        d="M184.7268,307.2176a9.4556,9.4556,0,0,0-6.23,2.3424l-17.17,15.0374h23.4a8.69,8.69,0,0,0,0-17.38Z"
      />
      <path
        className="skyBlueLightest thinnestStroke join"
        d="M177.8633,336.2918a5.7772,5.7772,0,0,1-4.1679-1.7766l-9.5191-9.9179h13.5464a5.9175,5.9175,0,0,1,5.9176,5.9176A5.777,5.777,0,0,1,177.8633,336.2918Z"
      />
      <path
        className="skyBlueLightest thinnestStroke join"
        d="M130.5174,336.2918a5.7774,5.7774,0,0,0,4.1679-1.7766l9.5191-9.9179H130.658a5.9175,5.9175,0,0,0-5.9176,5.9176A5.777,5.777,0,0,0,130.5174,336.2918Z"
      />
      <path
        className="skyBlueLight thinnestStroke join"
        d="M123.6537,307.2176a9.4556,9.4556,0,0,1,6.23,2.3424l17.17,15.0374h-23.4a8.69,8.69,0,0,1,0-17.38Z"
      />
      <path
        className="lobsterMedium thinnestStroke join"
        d="M158.7642,311.1507h-9.1477a6.7232,6.7232,0,1,0,0,13.4464h9.1477a6.7232,6.7232,0,1,0,0-13.4464Z"
      />
      <path
        className="white thinnestStroke join"
        d="M154.19,304.1346a7.0161,7.0161,0,0,0-7.0161,7.0161h14.0322A7.0161,7.0161,0,0,0,154.19,304.1346Z"
      />
      <path
        className="lobsterLightest thinnestStroke join"
        d="M166.2394,326.92a4.7967,4.7967,0,0,0-4.1276-2.3226H146.2687a4.7963,4.7963,0,0,0-4.1276,2.3226,14.03,14.03,0,0,0,1.3725,16.2433l1.8542,2.18,8.8225,10.3727,8.8226-10.3727,1.8542-2.18a13.9617,13.9617,0,0,0,3.3087-8.1929h0A14.0229,14.0229,0,0,0,166.2394,326.92Z"
      />
      <path
        className="midnight"
        d="M165.2822,294.0314a11.8965,11.8965,0,0,0-11.0921,7.8542,11.8942,11.8942,0,0,0-11.092-7.8542,3.0039,3.0039,0,1,0,0,6.0078,5.7307,5.7307,0,0,1,5.7007,4.8916l.3413,2.24,2.0694-.9179c.7368-.3262,1.9946-.9629,2.5371-1.2833l.444-.2612.4412.2593c.5469.3242,1.8047.96,2.5386,1.2842l2.0713.9189.34-2.24a5.73,5.73,0,0,1,5.7-4.8916,3.0039,3.0039,0,1,0,0-6.0078Z"
      />
    </svg>
  );
};
