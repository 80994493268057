/** @jsx jsx */
import { jsx } from "theme-ui";
import { FunctionComponent } from "react";

export const Microphone: FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 512 512">
      <path
        className="skyBlueLight"
        d="M193.78,184.24V139.47a61.89,61.89,0,0,1,123.78,0h0v44.77Z"
      />
      <path
        className="lobsterLightest"
        d="M255.67,319.2a61.82,61.82,0,0,1-61.89-61.88V218.47H318.22v38.85C317.56,291.55,289.91,319.2,255.67,319.2Z"
      />
      <rect
        className="lobster"
        x="193.78"
        y="184.24"
        width="124.43"
        height="34.23"
      />
      <rect
        className="lobsterLight"
        x="200.37"
        y="412.03"
        width="110.6"
        height="22.38"
      />
      <rect
        className="lobsterLight"
        x="223.41"
        y="388.99"
        width="63.86"
        height="23.04"
      />
      <path
        className="midnight"
        d="M262.17,349.31C309.1,346.7,346.36,308,347,261.25V198.06a5.58,5.58,0,0,0-5.08-5.08H323.3V139.47a67,67,0,0,0-67-67c-37.29,0-67.63,30-67.63,67V193H170.08a4.72,4.72,0,0,0-5.08,5.08v63.21c0,46.74,37,85.41,84.17,88l1.42.07v34.53H230a11.67,11.67,0,0,0-11.66,11.66V407H207a11.68,11.68,0,0,0-11.67,11.67v9.21A11.68,11.68,0,0,0,207,439.5h97.44a11.68,11.68,0,0,0,11.67-11.67v-9.21A11.68,11.68,0,0,0,304.39,407H293V395.57a11.67,11.67,0,0,0-11.66-11.66h-20.6V349.38Zm-63.3-209.84a56.8,56.8,0,0,1,113.6,0v39.68H198.87Zm0,49.85H313.13v24.07H198.87ZM313.13,222.9v33.76a56.8,56.8,0,0,1-113.6,0V222.9Zm-7.24,194.22v12.21H205.45V417.12Zm-23-23.05V407H228.49V394.07Zm-26-54.27h-2.48c-42.83,0-78.58-34.66-79.91-77.83V203.81H188v53.51a67,67,0,0,0,133.94,0V203.81h13.5l1.35,57.48A80,80,0,0,1,256.9,339.8Z"
      />
    </svg>
  );
};
