/** @jsx jsx */
import { jsx } from "theme-ui";
import { FunctionComponent } from "react";

export const OpenHand: FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 512 512">
      <path
        className="lobsterLightest"
        d="M390.34,217l.37.74-31.32,16.88a32.89,32.89,0,0,0-21.74-8.18h-9.8l26.08-14.15A30.11,30.11,0,0,1,390.34,217Z"
      />
      <path
        className="lobsterLightest transparent"
        d="M427,223.49c-5.45-9.58-18.42-15-28.22-10l-39.35,21.22a33,33,0,0,1,10.76,19.46l8.1-4.28Z"
      />
      <path
        className="white"
        d="M432.86,220.39l-62.71,33.76a32.89,32.89,0,0,0-32.5-27.64H293.43a51.91,51.91,0,0,1-22.84-5.23L266,219.07a73.53,73.53,0,0,0-76.27,7.44l-11,9.8c-6,5.23-71.56,38.76-71.56,38.76l45.18,69.13,66.39-37.95a24.47,24.47,0,0,1,18.06-2.36l66.39,12.23a80.34,80.34,0,0,0,50-7.07l115-78.93a26.45,26.45,0,0,0-35.37-9.73Z"
      />
      <path
        className="midnight"
        d="M352.05,208.81l-25.18,13.71H293.43a49.55,49.55,0,0,1-21.21-4.84l-4.58-2.21a77.26,77.26,0,0,0-80.32,7.83l-.17.13-.09.06-11,9.81c-4,3.52-44.46,24.84-70.7,38.31a4.34,4.34,0,0,0-2,2.51,3.77,3.77,0,0,0,.52,3.09l45.19,69.15a3.81,3.81,0,0,0,3.26,1.76,4.45,4.45,0,0,0,2-.54l66.37-37.94a20.77,20.77,0,0,1,15.15-2.05l.2.06,66.57,12.25a85.05,85.05,0,0,0,52.52-7.4,1,1,0,0,0,.29-.15l.23-.14,114.9-78.89a4,4,0,0,0,1.11-5.29h0A30.51,30.51,0,0,0,431,216.93l-2.73,1.45-.26-.33c-7.7-9.61-20.93-13.12-30.87-8.22v0l-5.5,3-.26-.23A34,34,0,0,0,352.05,208.81Zm82.78,15.05a22.57,22.57,0,0,1,27.73,4.82l.38.43L351.35,305.59a77,77,0,0,1-47.32,6.6L237.71,300a28.69,28.69,0,0,0-20.89,2.82L153.63,338.9,112.9,276.58l.5-.26c14.54-7.46,62.44-32.19,68-37.14l10.84-9.65a69.29,69.29,0,0,1,72.1-6.93l4.57,2.21a57,57,0,0,0,24.54,5.62h44.29a28.94,28.94,0,0,1,28.57,24.22l.11.61-33.34-1.5a188.57,188.57,0,0,0-34.69,1.62,4,4,0,0,0-2.6,1.54,3.85,3.85,0,0,0-.74,2.9,4,4,0,0,0,1.54,2.6,3.87,3.87,0,0,0,2.9.74,179.57,179.57,0,0,1,33.26-1.56l37.73,1.7h.2a3.78,3.78,0,0,0,2.67-1.11,3.86,3.86,0,0,0,1.25-2.81c0-.86,0-1.75-.07-2.69l0-.33Zm-34.33-6.93c6.18-3.13,14.71-1.14,20.27,4.74l.45.48-48.49,26.09-.18-.57a36.31,36.31,0,0,0-6.4-11.63l-.38-.47Zm-17.11-.57.7.46L359.86,229.9l-.26-.19A37.14,37.14,0,0,0,344.12,223l-1.4-.25,13.18-7.16A26.18,26.18,0,0,1,383.39,216.36Z"
      />
      <path
        className="skyBlue lineCap lineJoin thinStroke"
        d="M135.23,243.93l58.91,92.67L149.46,365,90.55,272.33Z"
      />
      <path
        className="midnight"
        d="M154.89,337.72A7.33,7.33,0,1,1,165,339.89,7.28,7.28,0,0,1,154.89,337.72Z"
      />
    </svg>
  );
};
