/** @jsx jsx */
import { jsx } from "theme-ui";
import { FunctionComponent } from "react";

export const MagicWand: FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 512 512">
      <path
        className="skyBlueLightest"
        d="M324.2633,198.3309a11.2633,11.2633,0,0,1,0,15.93l-39.83,39.83L260.539,230.1956l39.83-39.83a11.2633,11.2633,0,0,1,15.93,0Z"
      />
      <path
        className="skyBlue"
        d="M284.4337,254.09,145.55,392.9736a11.2633,11.2633,0,0,1-15.93,0l-7.9649-7.9649a11.2633,11.2633,0,0,1,0-15.93L260.539,230.1956Z"
      />
      <path
        className="midnight"
        d="M381.61,132.88a4.6314,4.6314,0,1,0,3.2812-7.9091h-.0039A4.6332,4.6332,0,0,0,381.61,132.88Z"
      />
      <path
        className="midnight"
        d="M381.6087,285.6482a4.6343,4.6343,0,0,0,3.2783,7.91,4.632,4.632,0,0,0,3.2725-7.91A4.6479,4.6479,0,0,0,381.6087,285.6482Z"
      />
      <path
        className="midnight"
        d="M228.84,126.3289a4.6049,4.6049,0,0,0-3.2773-1.3584H225.56a4.6333,4.6333,0,1,0,3.28,1.3584Z"
      />
      <path
        className="midnight"
        d="M327.0452,195.408l-7.9648-7.9649a15.4178,15.4178,0,0,0-21.7754,0c-6.0322,6.0342-172.6768,172.6787-178.7129,178.7139a15.4165,15.4165,0,0,0,0,21.7744l7.9648,7.9648a15.4142,15.4142,0,0,0,21.7754,0L327.0452,217.1833A15.4158,15.4158,0,0,0,327.0452,195.408ZM142.4866,390.0515a7.1367,7.1367,0,0,1-10.084,0l-7.9648-7.9648a7.1248,7.1248,0,0,1,0-10.084l135.96-135.961,18.05,18.0489ZM321.2015,211.3386l-36.9073,36.9072-18.05-18.05L303.1507,193.29a7.1353,7.1353,0,0,1,10.0859-.001l7.9649,7.9649A7.14,7.14,0,0,1,321.2015,211.3386Z"
      />
      <path
        className="midnight"
        d="M248.8968,205.1316H215.0989a4.1328,4.1328,0,1,0,0,8.2656h33.7979a4.1328,4.1328,0,0,0,0-8.2656Z"
      />
      <path
        className="midnight"
        d="M395.35,205.1316H361.553a4.1328,4.1328,0,1,0,0,8.2656H395.35a4.1328,4.1328,0,1,0,0-8.2656Z"
      />
      <path
        className="midnight"
        d="M309.3577,265.5925a4.1328,4.1328,0,0,0-8.2656,0V299.39a4.1328,4.1328,0,1,0,8.2656,0Z"
      />
      <path
        className="midnight"
        d="M309.3577,119.1384a4.1328,4.1328,0,0,0-8.2656,0v33.7979a4.1328,4.1328,0,0,0,8.2656,0Z"
      />
      <path
        className="midnight"
        d="M371.8773,270.071l-23.9-23.9a4.1335,4.1335,0,1,0-5.8457,5.8457l23.9013,23.9a4.1332,4.1332,0,0,0,5.8448-5.8457Z"
      />
      <path
        className="midnight"
        d="M268.3177,166.5115l-23.9-23.9a4.1335,4.1335,0,0,0-5.8457,5.8457l23.9,23.9a4.1335,4.1335,0,1,0,5.8457-5.8457Z"
      />
      <path
        className="midnight"
        d="M340.9212,169.4333a4.1336,4.1336,0,0,0,7.0557,2.9239l23.9-23.9a4.1332,4.1332,0,1,0-5.8448-5.8457l-23.9,23.9A4.1041,4.1041,0,0,0,340.9212,169.4333Z"
      />
    </svg>
  );
};
