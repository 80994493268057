/** @jsx jsx */
import { jsx } from "theme-ui";
import { FunctionComponent } from "react";

export const Wrench: FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 512 512">
      <path
        className="midnight"
        d="M388.2075,321.6524a47.0561,47.0561,0,0,1-.05,66.62c-17.19,17.13-47.37,19.32-66.68,0a47.1372,47.1372,0,0,1-13.74-33.74l-114.96-114.96a65.8271,65.8271,0,0,1-63.69-17.07c-17.41-17.42-24.94-43.8-13.91-73.92l42.14,42.13,27.76-5.57,5.57-27.77-42.13-42.13c30.41-11.09,56.5-3.51,73.92,14.03a65.6634,65.6634,0,0,1,17.07,63.68l114.96,114.97A47.6077,47.6077,0,0,1,388.2075,321.6524Zm-6.66,60.01a37.698,37.698,0,0,0-.06-53.34c-13.28-13.28-30.81-10.81-30.81-10.81l-121.92-121.91c8.11-20.81,1.9-44.78-13.04-59.72a56.1925,56.1925,0,0,0-49.15-15.81l34.26,34.26-7.76,38.85-38.86,7.76-34.26-34.25a56.5792,56.5792,0,0,0,75.53,62.19l121.92,121.97s-2.3,17.71,10.8,30.81A37.7463,37.7463,0,0,0,381.5475,381.6624Z"
      />
      <path
        className="skyBlue"
        d="M381.4875,328.3224c-13.28-13.28-30.81-10.81-30.81-10.81l-121.92-121.91c8.11-20.81,1.9-44.78-13.04-59.72a56.1925,56.1925,0,0,0-49.15-15.81l34.26,34.26-7.76,38.85-38.86,7.76-34.26-34.25a56.5792,56.5792,0,0,0,75.53,62.19l121.92,121.97s-2.3,17.71,10.8,30.81a37.6994,37.6994,0,1,0,53.29-53.34Zm-9.945,43.3415c-7.415,7.4148-22.36,10.9788-33.3387,0a23.574,23.574,0,1,1,33.3387,0Z"
      />
      <path
        className="midnight"
        d="M371.5425,338.3252a23.574,23.574,0,0,0-33.3387,33.3387c10.9788,10.9788,25.9237,7.415,33.3387,0A23.5909,23.5909,0,0,0,371.5425,338.3252Zm-6.6677,26.671a14.48,14.48,0,0,1-20.0032,0,14.1544,14.1544,0,0,1,0-20.0032c7.6449-7.6449,17.2441-2.7591,20.0032,0A14.1546,14.1546,0,0,1,364.8748,364.9962Z"
      />
      <path
        className="skyBlueLight"
        d="M193.22,192.4166l145.0185,145.877a23.5624,23.5624,0,0,1,33.6478,32.9892L382.12,381.06a37.6909,37.6909,0,0,0-.6321-52.738c-13.28-13.28-30.81-10.81-30.81-10.81l-121.92-121.91c8.11-20.81,1.9-44.78-13.04-59.72a56.1925,56.1925,0,0,0-49.15-15.81l34.26,34.26Z"
      />
    </svg>
  );
};
