/** @jsx jsx */
import { jsx } from "theme-ui";
import { FunctionComponent } from "react";

export const Wool: FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 512 512">
      <path
        className="midnight"
        d="M380.7205,150.0271a19.293,19.293,0,0,0-19.293,19.293,19.0118,19.0118,0,0,0,2.45,9.2124l-9.5211,8.9424A121.6876,121.6876,0,0,0,323.97,157.0883l8.9519-9.5114a19.0132,19.0132,0,0,0,9.2124,2.45,19.4281,19.4281,0,1,0-16.2736-9.0243L315.6933,151.831A120.4994,120.4994,0,0,0,154.939,322.8588l-43.0138,45.7341a4.8249,4.8249,0,0,0,7.0324,6.6079l41.7645-44.4125a121.8969,121.8969,0,0,0,19.9442,19.9441L136.2538,392.497a4.8248,4.8248,0,1,0,6.6078,7.0323l45.7341-43.0138a120.2968,120.2968,0,0,0,157.6578-21.3284l20.2577,1.7412a10.2687,10.2687,0,0,1-.8875,20.4988,18.6853,18.6853,0,0,0-18.666,18.666v19.92a4.8233,4.8233,0,0,0,9.6465,0v-19.92a9.0292,9.0292,0,0,1,9.0243-9.0195,19.9152,19.9152,0,0,0,1.7074-39.7532l-13.92-1.1962a120.1618,120.1618,0,0,0,6.2075-130.363l10.8282-10.1674A19.293,19.293,0,1,0,380.72,150.0271Z"
      />
      <path
        className="lobster"
        d="M342.1344,121.0876a9.6465,9.6465,0,1,1-6.5644,16.674v-.029l-.0579-.0337a9.6465,9.6465,0,0,1,6.6223-16.6113Z"
      />
      <path
        className="skyBlueLight"
        d="M155.0838,303.6333A173.2126,173.2126,0,0,0,310.7109,160.0884a111.0769,111.0769,0,0,1,16.0759,11.3009A164.9552,164.9552,0,0,1,163.8621,318.841,111.1375,111.1375,0,0,1,155.0838,303.6333Z"
      />
      <path
        className="skyBlueLightest"
        d="M144.381,256.1387a110.6528,110.6528,0,0,1,3.082-25.8864,174.2267,174.2267,0,0,0,13.3846,33.8737,160.6036,160.6036,0,0,1-15.8444,3.7236,111.8171,111.8171,0,0,1-.6222-11.7109Z"
      />
      <path
        className="skyBlueLightest"
        d="M170.1709,261.1018A164.5592,164.5592,0,0,1,153.29,212.5944a110.9643,110.9643,0,0,1,14.764-24.83,177.2539,177.2539,0,0,0,24.2513,63.5512,163.1125,163.1125,0,0,1-22.1339,9.7863Z"
      />
      <path
        className="skyBlueLight"
        d="M283.7248,148.9081a109.4773,109.4773,0,0,1,17.9425,6.4825A163.5468,163.5468,0,0,1,151.1335,294.2424a110.1784,110.1784,0,0,1-4.6785-16.8813,174.3221,174.3221,0,0,0,137.27-128.453Z"
      />
      <path
        className="skyBlueLightest"
        d="M227.8715,225.7956a166.8361,166.8361,0,0,1-25.9973-66.831,110.6034,110.6034,0,0,1,16.0469-7.2349,185.7723,185.7723,0,0,0,27.1694,55.043A165.1458,165.1458,0,0,1,227.8715,225.7956Z"
      />
      <path
        className="skyBlueLightest"
        d="M274.2953,146.863a162.2562,162.2562,0,0,1-23.19,51.7439,176.2948,176.2948,0,0,1-23.9764-49.7663A110.3809,110.3809,0,0,1,274.2953,146.863Z"
      />
      <path
        className="skyBlueLightest"
        d="M193.0042,164.41a176.5593,176.5593,0,0,0,27.5842,67.8054,166.0559,166.0559,0,0,1-19.8767,14.3058,167.2961,167.2961,0,0,1-24.2465-68.3022A111.9076,111.9076,0,0,1,193.0042,164.41Z"
      />
      <path
        className="skyBlueLight"
        d="M171.1693,328.3043A174.8141,174.8141,0,0,0,335.5121,179.6081a111.4943,111.4943,0,0,1,14.981,19.6162A163.7254,163.7254,0,0,1,187.79,342.9573h-1.4469A111.6809,111.6809,0,0,1,171.1693,328.3043Z"
      />
      <path
        className="skyBlue"
        d="M282.6444,324.4457a174.4727,174.4727,0,0,0,50.8323,10.3121,111.5293,111.5293,0,0,1-19.1291,15.2174,166.118,166.118,0,0,1-57.6813-11.5759,173.6321,173.6321,0,0,0,25.9781-13.9488Z"
      />
      <path
        className="skyBlue"
        d="M341.8981,325.3717a165.9212,165.9212,0,0,1-49.477-7.77,173.5778,173.5778,0,0,0,18.8107-16.4425,329.5184,329.5184,0,0,0,40.4864,9.7864,111.2934,111.2934,0,0,1-9.82,14.4312Z"
      />
      <path
        className="skyBlue"
        d="M318.5294,293.3308a174.0878,174.0878,0,0,0,14.6289-19.2448,185.5031,185.5031,0,0,0,29.9042,8.3346,109.98,109.98,0,0,1-6.7525,19.52,351.5011,351.5011,0,0,1-37.7806-8.6047Z"
      />
      <path
        className="skyBlueMedium"
        d="M199.8484,352.1456a173.3993,173.3993,0,0,0,43.935-8.7638,175.4468,175.4468,0,0,0,54.1459,15.1691,110.6355,110.6355,0,0,1-98.0809-6.4053Z"
      />
      <path
        className="skyBlueMedium"
        d="M364.9773,272.9719a175.65,175.65,0,0,1-26.6726-7.2687,172.2521,172.2521,0,0,0,19.5728-51.7921A111.0072,111.0072,0,0,1,364.9773,272.9719Z"
      />
      <path
        className="lobster"
        d="M380.72,178.9666a9.6025,9.6025,0,0,1-6.9647-3.0049l-.0338-.0578h-.0289a9.6176,9.6176,0,1,1,7.0274,3.0627Z"
      />
    </svg>
  );
};
