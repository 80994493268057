/** @jsx jsx */
import { jsx } from "theme-ui";
import { FunctionComponent } from "react";

export const Focus: FunctionComponent<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 512 512">
      <path
        className="midnight"
        d="M401.4147,359.8409,342.82,264.8145a3.6842,3.6842,0,0,0-5.07-1.2021l-5.0977,3.1455-12.2944-19.9385.411-.459.8232-.8379A78.9643,78.9643,0,1,0,201.4092,143.7237l-.4492.6191H107.8511a4.6883,4.6883,0,0,0-4.6831,4.6827V394.4288a4.6883,4.6883,0,0,0,4.6831,4.6826H278.4863a4.6881,4.6881,0,0,0,4.6836-4.6826V267.1153l1.1377-.2832c1.7119-.4248,3.4112-.9072,5.0518-1.4336l1.1181-.3584,1.4184-.3855,12.1673,19.73-5.1016,3.1455a3.689,3.689,0,0,0-1.2012,5.0684l58.5948,95.0273a25.3421,25.3421,0,0,0,34.8476,8.2647l1.9463-1.2012A25.3576,25.3576,0,0,0,401.4147,359.8409Z"
      />
      <path
        className="white"
        d="M273.8037,389.7452H112.5347V153.7091h82.66l-1.0351,2.15a78.9192,78.9192,0,0,0,71.21,113.29c2.168,0,4.4571-.1026,6.8057-.3047l1.6289-.406Z"
      />
      <path
        className="lobsterLightest"
        d="M300.5374,261.9486a73.5531,73.5531,0,0,0,7.4906-4.6413c3.46-2.5585,5.5536-4.4,5.5536-4.4l10.7355,17.2274-13.4942,8.32Z"
      />
      <rect
        className="lobsterMedium"
        x="327.0611"
        y="276.9793"
        width="35.2029"
        height="82.5094"
        transform="matrix(0.8512, -0.5248, 0.5248, 0.8512, -115.7372, 228.2496)"
      />
      <path
        className="lobster"
        d="M388.4948,387.1426l.001-.0009-1.9463,1.2011a16.4693,16.4693,0,0,1-22.6484-5.3711l-7.128-11.56,29.9659-18.4766,7.1289,11.5606A16.4788,16.4788,0,0,1,388.4948,387.1426Z"
      />
      <path
        className="skyBlueMedium"
        d="M324.48,153.6758a69.5713,69.5713,0,1,0-22.7119,95.7706A69.238,69.238,0,0,0,324.48,153.6758Z"
      />
      <path
        className="midnight"
        d="M277.4268,138.8018a53.4075,53.4075,0,0,0-12.3282-1.4511,53.1063,53.1063,0,1,0,12.3282,1.4511Zm30.125,61.4385A42.3041,42.3041,0,0,1,265.498,233.252a49.478,49.478,0,0,1-16.63-2.8818c-11.4478-4.1026-19.64-11.8721-23.0674-21.877l-.6807-1.9863h44.1177a4.6831,4.6831,0,0,0,0-9.3662h-46.938l-.1689-1.3076a43.5545,43.5545,0,0,1,.2485-12.8076l.21-1.251h46.6484a4.6831,4.6831,0,0,0,0-9.3662H225.5024l1.128-2.1875A43.48,43.48,0,0,1,307.5518,200.24Z"
      />
      <path
        className="white"
        d="M307.5518,200.24A42.3041,42.3041,0,0,1,265.498,233.252a49.478,49.478,0,0,1-16.63-2.8818c-11.4478-4.1026-19.64-11.8721-23.0674-21.877l-.6807-1.9863h44.1177a4.6831,4.6831,0,0,0,0-9.3662h-46.938l-.1689-1.3076a43.5545,43.5545,0,0,1,.2485-12.8076l.21-1.251h46.6484a4.6831,4.6831,0,0,0,0-9.3662H225.5024l1.128-2.1875A43.48,43.48,0,0,1,307.5518,200.24Z"
      />
      1
    </svg>
  );
};
